// @flow

import './index.less';

import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useMemo } from 'react';
import ArticlePreview from '_components/ArticlePreview';
import Tag from '_components/Tag';

type Props = {
  data: Object,
};

const HighlightArticles = ({ data }: Props): React$Node => {
  const { t } = useTranslation();
  const articles = useMemo(() => data.articles.edges.map((edge) => edge.node), [data]);

  // eslint-disable-next-line no-console
  console.log({ articles });

  const primaryArticle = articles[0];
  const secondaryArticles = [articles[1], articles[2]];

  return (
    <div className="HighlightArticlesContainer">
      <Link key={primaryArticle.id} to={`/article/${primaryArticle.slug.current}`}>
        <div className="PrimaryArticle">
          <div className="Image">
            <div className="ImageWrapper">
              {primaryArticle.mainImage ? (
                <img src={primaryArticle.mainImage.asset.url} alt={primaryArticle.mainImage.asset.title} />
              ) : (
                <div className="EmptyThumbnail" />
              )}
            </div>
            {primaryArticle.categories[0] && (
              <div className="CategoryTag">
                <Tag title={primaryArticle.categories[0].title} />
              </div>
            )}
          </div>
          <div className="Info">
            <h1 className="Title">{primaryArticle.title}</h1>
            <h1 className="ContentPreview">{primaryArticle.previewSnippet}</h1>
            <h1 className="AuthorTag">
              {t('Common_By')} {primaryArticle.author.name}
            </h1>
          </div>
        </div>
      </Link>
      <div className="SecondaryArticleList">
        {secondaryArticles.map((article) => (
          <div key={article.id} className="SecondaryArticleListItem">
            <ArticlePreview article={article} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default HighlightArticles;
