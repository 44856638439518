// @flow

import './index.less';

import React, { useMemo } from 'react';

import CategoryPreview from '_components/CategoryPreview';

type Props = {
  data: Object,
};

const titleToHtmlId = (title) => title.toLowerCase().replace(/\s/g, '');

const scrollTo = (e, anchor) => {
  const anchorElement = document.querySelector(`#${anchor}`);
  if (!anchorElement) return;
  anchorElement.scrollIntoView({
    behavior: 'smooth',
  });
  window.history.pushState({}, '', `#${anchor}`);
};

const Categories = ({ data }: Props): React$Node => {
  let categories = useMemo(() => data.categories.edges.map((edge) => edge.node), [data]);

  categories = [...categories];

  return (
    <div>
      <section className="Section">
        <div className="CategoryList">
          {categories.map((category) =>
            category.externalUrl ? (
              <div className="CategoryItem">
                <a href={category.externalUrl} target="_blank" key={category.id} rel="noreferrer">
                  <CategoryPreview key={category.id} category={category} />
                </a>
              </div>
            ) : (
              <div
                key={category.id}
                role="link"
                onClick={(e) => scrollTo(e, titleToHtmlId(category.title))}
                className="CategoryItem"
                aria-hidden="true"
              >
                <CategoryPreview key={category.id} category={category} />
              </div>
            )
          )}
        </div>
      </section>
    </div>
  );
};

export default Categories;
