// @flow

import './index.less';

import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

type Props = {
  article: Object,
};

const ArticlePreview = ({ article }: Props): React$Node => {
  const { t } = useTranslation();

  return (
    <Link key={article.id} to={`/article/${article.slug.current}`}>
      <div className="ArticlePreview">
        <div className="Image">
          <div className="ImageWrapper">
            {article.mainImage ? (
              <img src={article.mainImage.asset.url} alt={article.mainImage.asset.title} />
            ) : (
              <div className="EmptyThumbnail" />
            )}
          </div>
        </div>
        <div className="Info">
          {article.categories.length > 0 && (
            <span className="Category" color="red">
              {article.categories[0]?.title}
            </span>
          )}
          <h1 className="Title">{article.title}</h1>
          <div className="AuthorTag">
            {t('Common_By')} {article.author.name}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ArticlePreview;
