// @flow

import './index.less';

import React from 'react';

type Props = {
  category: Object,
};

const CategoryPreview = ({ category }: Props): React$Node => (
  <div className="CategoryPreview">
    <div className="Image">
      {category.mainImage ? (
        <img src={category.mainImage.asset.url} alt={category.mainImage.asset.title} />
      ) : (
        <div className="EmptyThumbnail" />
      )}
    </div>
    <div className="Title">{category.title}</div>
  </div>
);

export default CategoryPreview;
