// @flow
import { graphql } from 'gatsby';
import React from 'react';

import BottomSection from '_components/BottomSection';
import Categories from '_components/Categories';
import HighlightArticles from '_components/HighlightArticles';
import Layout from '_components/Layout';
// import SignUpBox from '_components/SignUpBox';
import Seo from '_components/Seo';
import useScript from '_hooks/useScript';

type Props = {
  data: Object,
};

function Page({ data }: Props): React$Node {
  const quizScriptStatus = useScript(
    'https://lq3-production01.s3.amazonaws.com/lead_quizzes_3.0/tracking/js/properties/toflmcradhyq3g.js'
  );

  return (
    <Layout>
      <Seo title={data.site.title} description={data.site.description} keywords={data.site.keywords} />
      {quizScriptStatus === 'ready' && <span style={{ display: 'none' }}>{quizScriptStatus}</span>}
      <Categories data={data} />
      <HighlightArticles data={data} />
      {/* <SignUpBox /> */}
      <BottomSection data={data} />
    </Layout>
  );
}

export default Page;

export const pageQuery: any = graphql`
  fragment articleInfo on SanityArticle {
    id
    title
    previewTitle
    publishedAt
    previewSnippet
    readingTime
    slug {
      current
    }
    mainImage {
      asset {
        id
        title
        description
        label
        url
      }
    }
    categories {
      id
      title
      description
    }
    tags {
      id
      title
      description
    }
    author {
      id
      name
      slug {
        current
      }
    }
  }
  query($language: String!) {
    articles: allSanityArticle(sort: { fields: _createdAt, order: DESC }) {
      edges {
        node {
          ...articleInfo
        }
      }
    }
    categories: allSanityCategory(sort: { fields: order }) {
      edges {
        node {
          id
          title
          externalUrl
          mainImage {
            asset {
              id
              title
              description
              label
              url
            }
          }
        }
      }
    }
    tags: allSanityTag {
      edges {
        node {
          id
          title
          description
        }
      }
    }
    site: sanitySiteSettings {
      title
      description
      keywords
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
